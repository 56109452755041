import IconButton from "@material-ui/core/IconButton"
import { makeStyles } from "@material-ui/core/styles"
import ExpandLessIcon from "@material-ui/icons/ExpandLess"
import React, { useEffect, useState } from "react"

const useStyles = makeStyles(theme => ({
  toTop: {
    zIndex: 2,
    position: "fixed",
    bottom: "2vh",
    backgroundColor: "rgba(209,209,209, 0.80)",
    color: "rgba(0, 0, 0, 0.80)",
    transition: "all .2s ease-in-out",
    "&:hover, &.Mui-focusVisible": {
      //transition: "0.3s",
      color: "rgba(0, 0, 0, 0.87)",
      backgroundColor: "rgba(209,209,209, 1)",
      transform: "scale(1.5)",
    },
    [theme.breakpoints.up("xs")]: {
      right: "5%",
      backgroundColor: "rgba(209,209,209, 0.80)",
    },
    [theme.breakpoints.up("lg")]: {
      right: "6.5%",
    },
  },
}))

const Scroll = ({ showBelow }) => {
  const classes = useStyles()

  const [show, setShow] = useState(showBelow ? false : true)

  const handleScroll = () => {
    if (window.pageYOffset > showBelow) {
      if (!show) setShow(true)
    } else {
      if (show) setShow(false)
    }
  }

  const handleClick = () => {
    window[`scrollTo`]({ top: 0, behavior: `smooth` })
  }

  useEffect(() => {
    if (showBelow) {
      window.addEventListener(`scroll`, handleScroll)
      return () => window.removeEventListener(`scroll`, handleScroll)
    }
  })

  return (
    <div>
      {show && (
        <IconButton
          onClick={handleClick}
          className={classes.toTop}
          aria-label="to top"
          component="span"
        >
          <ExpandLessIcon />
        </IconButton>
      )}
    </div>
  )
}
export default Scroll
