import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { BLOCKS, INLINES, MARKS } from "@contentful/rich-text-types"
import Box from "@material-ui/core/Box"
import Grid from "@material-ui/core/Grid"
import Hidden from "@material-ui/core/Hidden"
import Typography from "@material-ui/core/Typography"
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos"
import { graphql, Link } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import React, { useEffect, useState } from "react"
import SyntaxHighlighter from "react-syntax-highlighter"
import { obsidian } from "react-syntax-highlighter/dist/esm/styles/hljs"
import styled from "styled-components"
import FadeIn from "../components/animations/fadeIn"
import Scroll from "../components/elements/Scroll"
import Layout from "../components/pageSections/layout"
import SEO from "../components/pageSections/seo"

export const query = graphql`
  query($slug: String!) {
    contentfulBlogPost(slug: { eq: $slug }) {
      title
      introduction
      bannerOpacity
      postDate(formatString: "MMMM Do, YYYY")
      post {
        json
      }
      postImage {
        fluid(
          maxWidth: 1920
          maxHeight: 400
          resizingBehavior: FILL
          quality: 100
        ) {
          ...GatsbyContentfulFluid
        }

        title
      }
    }
  }
`

const InlineImage = styled.img`
  max-width: 60%;
  height: auto;
  margin: 3rem 0;
`

const BlogSyntaxHighlighter = styled(SyntaxHighlighter)`
  margin: 3rem 0;
`

const BlogContent = styled(Box)`
  p, a, li {
    font-size: 1.0714285714285714rem;
    font-weight: 400;
    line-height: 1.6;
    margin-bottom: 16px;

    @media (min-width: 1280px) {
      font-size: 1.1667rem;
    }
    @media (min-width: 960px) {
      font-size: 1.1667rem;
    }
    @media (min-width: 600px) {
      font-size: 1.1667rem;
    }
  }

  & > h2 {
    margin-top: 5rem;
    font-family: "Bebas Neue", "sans-serif";
    font-size: 2.642857142857143rem;
    font-weight: 400;

    @media (min-width: 1280px) {
      font-size: 4.375rem;
    }
    @media (min-width: 960px) {
      margin-top: 7rem;
      font-size: 3.9583rem;
    }
    @media (min-width: 600px) {
      font-size: 3.3333rem;
    }
  }
`

const BackButton = styled(Link)`
  display: inline-block;
  margin-top: 2rem;
  font-size: 1.1rem;
  color: black;

  & > svg {
    position: relative;
    top: 3px;
    font-size: 18px;
  }
`
const BlogSplash = styled(BackgroundImage)`
  width: 100vw;
  height: 45vh;
  opacity: 1 !important;
  background-size: cover;
  background-size: cover;
  background-position: center;
`

const readTime = () => {
  const para = document.getElementsByTagName("p")
  const wordsPerMinute = 200
  let words = ""
  let numWords = []

  for (let i = 0; i < para.length; i++) {
    words += para[i].innerText
  }

  numWords = words.split(" ").length
  return Math.ceil(numWords / wordsPerMinute)
}

const Blog = props => {
  const website_url = "https://stevenewman.uk"
  const [wordCount, setwordCount] = useState(0)
  const [bannerOpacity, setBannerOpacity] = useState(0.4)

  useEffect(() => {
    setwordCount(readTime())
    if (props.data.contentfulBlogPost.bannerOpacity !== null) {
      setBannerOpacity(props.data.contentfulBlogPost.bannerOpacity)
    }
  }, [props.data.contentfulBlogPost.bannerOpacity])

  let splashStyle = {
    backgroundColor: `rgba(0, 0, 0, ${bannerOpacity})`,
  }
 
  // Contentful rendering options
  const options = {
    renderNode: {
      "embedded-asset-block": node => {
        const alt = node.data.target.fields.title["en-US"]
        const url = node.data.target.fields.file["en-US"].url
        return <InlineImage alt={alt} src={url} />
      },
      [BLOCKS.PARAGRAPH]: (node, children) => {
        if (
          node.content.length === 1 &&
          node.content[0].marks.find(x => x.type === "code")
        ) {
          return <div>{children}</div>
        }
        return <p>{children}</p>
      },
      [INLINES.HYPERLINK]: node => {
        return (
          <a
            href={node.data.uri}
            target={`${
              node.data.uri.startsWith(website_url) ? "_self" : "_blank"
            }`}
            rel={`${
              node.data.uri.startsWith(website_url) ? "" : "noopener noreferrer"
            }`}
          >
            {node.content[0].value}
          </a>
        )
      },
    },
    renderMark: {
      [MARKS.CODE]: code => {
        return (
          <BlogSyntaxHighlighter
            language="javascript"
            style={obsidian}
            showLineNumbers
          >
            {code}
          </BlogSyntaxHighlighter>
        )
      },
    },
  }

  return (
    <>
      <SEO
        title={props.data.contentfulBlogPost.title}
        description={props.data.contentfulBlogPost.introduction}
      />
      <BlogSplash
        fluid={props.data.contentfulBlogPost.postImage.fluid}
        style={splashStyle}
      ></BlogSplash>
      <Layout>
        <BackButton to="/blog">
          <ArrowBackIosIcon />
          Back
        </BackButton>
        <FadeIn thresh={0}>
          <Grid xs={12} container item>
            <Hidden smDown>
              <Grid xs={2} item />
            </Hidden>
            <Grid component="section" item xs={12} md={8}>
              <Box mt={"2rem"} mb={"4rem"}>
                <Typography variant="h1">
                  {props.data.contentfulBlogPost.title}
                </Typography>
                <Typography variant="h5" gutterBottom>
                  {props.data.contentfulBlogPost.postDate}
                </Typography>
              </Box>
              <BlogContent>
                <Typography variant="caption" gutterBottom>
                  Approx reading time: {wordCount} mins
                </Typography>
                {documentToReactComponents(
                  props.data.contentfulBlogPost.post.json,
                  options
                )}
              </BlogContent>
            </Grid>
          </Grid>
        </FadeIn>
        <Scroll showBelow={700} />
      </Layout>
    </>
  )
}

export default Blog
